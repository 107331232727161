import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
// import { Image } from "react-bootstrap";

// import {
//   RecaptchaVerifier,
//   signInWithPhoneNumber,
//   Auth,
//   ConfirmationResult,
//   UserCredential,
// } from "firebase/auth";
// import { auth } from "../../env/firebase.config";

import "./login.css";
// import { images } from "../../assets";

import Button from "../../components/Button";
import Input from "../../components/Input";

import { loginUser } from "../../services/AuthService";

import {
  getUserId,
  toast,
  updateUserAuthenticationStatus,
} from "../../utils/helper";
import { ROUTES } from "../../navigators/Routes";

// // declare const window: any;

interface LoginProps {}

const Login = (props: LoginProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    let id;
    let getData = async () => {
      id = await getUserId();
      if (id) {
        navigate(ROUTES.MAIN, { replace: true });
      }
    };
    getData();
  }, [navigate]);

  const [formValues, setFormValues] = useState({
    emailOrMobile: "",
    password: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    emailOrMobile: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);
  // const [phoneNumber, setPhoneNumber] = useState<string>("");
  // const [otp, setOtp] = useState<string>("");
  // const [verificationId, setVerificationId] = useState<string | null>(null);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));

    setErrorMessages((prevState) => ({
      ...prevState,
      [e.target.id]: "",
    }));
  };

  // const setUpRecaptcha = (auth: Auth) => {
  //   if (!window.recaptchaVerifier) {
  //     window.recaptchaVerifier = new RecaptchaVerifier(
  //       auth,
  //       "recaptcha-container",
  //       {
  //         size: "invisible",
  //         callback: () => {
  //           console.log("Recaptcha verified");
  //         },
  //       }
  //     );
  //   }
  // };

  // const handleSendOtp = async () => {
  //   setLoading(true);
  //   setUpRecaptcha(auth);
  //   const appVerifier = window.recaptchaVerifier;

  //   try {
  //     const confirmationResult: ConfirmationResult =
  //       await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
  //     setVerificationId(confirmationResult.verificationId);
  //     alert("OTP sent!");
  //   } catch (error) {
  //     console.error("Error during OTP sending:", error);
  //     alert("Failed to send OTP. Please try again.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const handleVerifyOtp = async () => {
  //   if (!verificationId) {
  //     alert("Verification ID not found. Please request OTP first.");
  //     return;
  //   }

  //   try {
  //     const credential = window.firebase.auth.PhoneAuthProvider.credential(verificationId, otp);
  //     const result: UserCredential = await auth.signInWithCredential(credential);
  //     alert("Phone number verified and user logged in!");
  //     navigate(ROUTES.MAIN, { replace: true });
  //   } catch (error) {
  //     console.error("Error during OTP verification:", error);
  //     alert("Failed to verify OTP. Please try again.");
  //   }
  // };

  const validateInputs = () => {
    let isValid = true;
    let errorMEssages = {
      emailOrMobile: "",
      password: "",
    };

    if (
      !formValues.emailOrMobile ||
      (formValues.emailOrMobile && formValues.emailOrMobile.trim().length === 0)
    ) {
      isValid = false;
      errorMEssages.emailOrMobile =
        "Please enter your email address or mobile number";
    } else if (
      !formValues.emailOrMobile.includes("@") &&
      isNaN(parseInt(formValues.emailOrMobile))
    ) {
      isValid = false;
      errorMEssages.emailOrMobile =
        "Please enter a valid email address or mobile number";
    }

    if (
      !formValues.password ||
      (formValues.password && formValues.password.trim().length === 0)
    ) {
      isValid = false;
      errorMEssages.password = "Please enter your password";
    }

    setErrorMessages(errorMEssages);

    return isValid;
  };

  const onBtnPress = () => {
    setLoading(true);
    const isValid = validateInputs();
    if (isValid) {
      login();
    } else {
      setLoading(false);
    }
  };

  const login = async () => {
    try {
      const preparedData = {
        email: formValues.emailOrMobile,
        password: formValues.password,
      };
      const response = await loginUser(preparedData);
      console.log("response :>> ", response);

      if (response && response.statusCode === 400) {
        toast.error(response.message);
        return;
      }

      await updateUserAuthenticationStatus(true, JSON.stringify(response.data));

      navigate(ROUTES.MAIN, { replace: true });
    } catch (error) {
      console.log("error in login :>> ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-body-tertiary">
      <div
        className="shadow p-5 rounded bg-white"
        style={{
          width: "40vw",
          // height: "60vh",
        }}
      >
        <h2>Login</h2>
        <p>to access dashboard</p>
        <form>
          <div className="mb-3">
            <Input
              type="text"
              id="emailOrMobile"
              aria-describedby="emailOrMobileHelp"
              placeholder="Email address or mobile number"
              onChange={onInputChange}
              value={formValues.emailOrMobile}
              error={errorMessages.emailOrMobile}
            />
            <Input
              isPassword
              id="password"
              aria-describedby="password"
              placeholder="Enter password"
              className="mt-3"
              onChange={onInputChange}
              value={formValues.password}
              error={errorMessages.password}
            />
          </div>
          <Button
            type="submit"
            className="w-100 mt-3"
            onClick={onBtnPress}
            isLoading={loading}
          >
            Login
          </Button>
          {/* OTP code
          <div>
            <h3>Phone Number OTP Authentication</h3>
            <input
              type="text"
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <button onClick={handleSendOtp} disabled={loading}>
              {loading ? "Sending OTP..." : "Send OTP"}
            </button>

            {verificationId && (
              <>
                <input
                  type="text"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
                <button onClick={handleVerifyOtp}>Verify OTP</button>
              </>
            )}

            <div id="recaptcha-container"></div>
          </div>*/}
        </form>

        <div className="mt-3">
          <NavLink
            to="/messages"
            className="text-decoration-none text-end d-flex justify-content-end"
          >
            Forgot Password?
          </NavLink>
          <div className="mt-3 d-flex">
            <p className="me-1">Don't have an Urbanlyfe Account? </p>
            <NavLink to={ROUTES.REGISTER} className="text-decoration-none">
              Register Now
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

// const Login = () => {
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [isOTP, setIsOTP] = useState(false);

//   const handleSubmit = (e: React.FormEvent) => {
//     e.preventDefault();
//     // Handle form submission
//     console.log("Phone number submitted:", phoneNumber);
//     setIsOTP(true);
//   };

//   return (
//     <div className="container-fluid min-vh-100 bg-white">
//       <div className="row h-100">
//         <div className="col-lg-6 d-flex flex-column p-4">
//           {/* Logo */}
//           <div className="mb-5">
//             <div className="d-flex align-items-center">
//               <Image src={images.LogoImage} />
//               <span className="ms-1 fw-bold text-3xl">urbanlyfe</span>
//             </div>
//           </div>

//           {/* Main Content */}
//           <div
//             className="card border-none rounded-4 shadow-lg p-4 mx-auto w-9"
//             style={{ maxWidth: "450px" }}
//           >
//             <div className="card-body">
//               <h1
//                 className="h4 mb-4 fw-bold text-center"
//                 style={{ lineHeight: "1.5" }}
//               >
//                 Dukaan se Customer tak,
//                 <br />
//                 Business ab hai Simple.{" "}
//                 <span
//                   role="img"
//                   aria-label="sparkles"
//                   className="bg-black rounded-circle p-1 text-sm"
//                 >
//                   ✨
//                 </span>
//               </h1>

//               <p className="mb-4 fw-bold text-center">
//                 Welcome{" "}
//                 <span role="img" aria-label="namaste">
//                   🙏
//                 </span>
//               </p>

//               <form onSubmit={handleSubmit}>
//                 <div className="mb-4">
//                   <div className="input-group">
//                     <span className="input-group-text bg-white border-end-0">
//                       +91
//                     </span>
//                     <input
//                       type="tel"
//                       className="form-control border-start-0"
//                       placeholder="10 digit mobile number"
//                       value={phoneNumber}
//                       onChange={(e) => setPhoneNumber(e.target.value)}
//                       maxLength={10}
//                       required
//                     />
//                   </div>
//                   <small className="text-muted">
//                     {isOTP
//                       ? `OTP sent to ${phoneNumber}`
//                       : "You will receive OTP on this number"}
//                     {isOTP && (
//                       <small className="fw-bold ml-2 text-sm hover:underline">
//                         EDIT
//                       </small>
//                     )}
//                   </small>
//                 </div>

//                 <button
//                   type="submit"
//                   className="w-100 py-2 mb-3 text-sm bg-black text-white rounded-3"
//                   disabled={phoneNumber.length !== 10}
//                 >
//                   {isOTP ? "Confirm OTP" : "Continue With Mobile Number"}
//                 </button>
//               </form>
//             </div>
//           </div>

//           {/* Footer */}
//           <div className="mt-auto text-center">
//             <small className="fw-bold">
//               By continuing you agree to our Terms & Policy
//               <br />
//               ©2024 Urbanlyfe, Urban Lyfe Green Private Limited. All rights
//               reserved.
//             </small>
//           </div>
//         </div>

//         {/* Right Side Images */}
//         <div className="col-lg-6 d-none d-lg-block w-4 p-3">
//           <div className="flex justify-content-center">
//             <Image
//               src={images.Owner}
//               alt="Business owner"
//               width={200}
//               height={200}
//               className="rounded-circle"
//             />
//           </div>
//           <div className="flex justify-content-end -mt-3">
//             <Image
//               src={images.Store}
//               alt="Customer interaction"
//               width={180}
//               height={180}
//               className="rounded-circle"
//             />
//           </div>
//           <div className="flex justify-content-center -mt-3">
//             <Image
//               src={images.Invent}
//               alt="Store management"
//               width={160}
//               height={160}
//               className="rounded-circle"
//             />
//           </div>
//           <div className="flex justify-content-end -mt-4">
//             <Image
//               src={images.Customer}
//               alt="Business success"
//               width={140}
//               height={140}
//               className="rounded-circle"
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export default Login;
