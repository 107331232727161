import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Button, CloseButton, Form, Table, Dropdown } from "react-bootstrap";
import { Image } from "react-bootstrap";

import DatePicker from "react-datepicker";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faClock,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { ROUTES } from "../../navigators/Routes";

import { NEW_PURCHASE_ORDER_TYPE } from "../../utils/types";
import {
  DISCOUNT_AMOUNT_TYPES,
  PAYMENT_MODE,
  PAYMENT_TERMS,
} from "../../utils/paymentConstants";

import { getVendors } from "../../controller/VendorController";
import { getProducts } from "../../controller/ProductsController";
import {
  CreatePO,
  getBillNo,
  getPONo,
} from "../../controller/PurchaseOrderController";

import VerticalInput from "../../components/VerticalInput/VerticalInput";
import SearchInput from "../../components/SearchInput/SearchInput";
import Dropdown1 from "../../components/Dropdown1/Dropdown1";
import DeletePopUp from "../../components/DeletePopUp/DeletePopUp";
import "./style.css";
import { toast } from "../../utils/helper";
import Loader from "../../components/Loader";

const NewPurchaseOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location;
  const title = state.title;
  const typeOfForm = state.type;

  const [loading, setLoading] = useState(false);

  // All & Filtered Vendors
  const [allVendors, setAllVendors] = useState<any>();
  const [filteredVendors, setFilteredVendors] = useState([]);

  // General Info
  const [poNo, setPONo] = useState<string>("");
  const [billNo, setBillNo] = useState<string>("");
  const [referenceNO, setReferenceNO] = useState<string>("");
  const orderDate = new Date();
  const [eDeliveryDate, setEDeliveryDate] = useState<Date>(new Date());

  // Vendor Info
  const [searchVendor, setSearchVendor] = useState<string>("");
  const [vendorId, setVendorId] = useState<number>();
  const [eMail, setEMail] = useState<string>();
  const [contactNo, setContactNo] = useState<string>();

  // Delivery Add
  const [contactPN, setContactPN] = useState("");
  const [address, setAddress] = useState("");

  // Customer Info
  const [customerId, setCustomerId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [typeOfSale, setTypeOfSale] = useState("-");

  // Product Info
  const [searchProduct, setSearchProduct] = useState("");
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [selectedProducts, setSelectedProducts] = useState<Array<any>>([]);
  const [qty, setQty] = useState<string>();

  // payment Details
  const [paymentTerms, setPaymentTerms] = useState("Due on Receipt");
  const [paymentMode, setPaymentMode] = useState("UPI");
  const [gst, setGst] = useState("0");
  const [discount, setDiscount] = useState("0");
  const [discountAmountType, setDiscountAmountType] = useState("%");
  const [adjustment, setAdjustment] = useState("0");
  const [total, setTotal] = useState<number>(0);
  const [totalWithGst, setTotalWithGst] = useState<number>(0);
  const [discountAmount, setDiscountAmount] = useState<number>(0);

  const [showDelete, setShowDelete] = useState(false);

  // PreRequired Data
  useEffect(() => {
    getPreRequiredDetails();
  }, []);

  // Vendors details Effect
  useEffect(() => {
    if (searchVendor.trim() && !vendorId) {
      const filtered = allVendors.filter((vendor: any) =>
        vendor.name.toLowerCase().includes(searchVendor.toLowerCase())
      );
      setFilteredVendors(filtered);
    } else {
      setFilteredVendors([]); // Clear the dropdown when input is empty
    }
  }, [searchVendor, allVendors, vendorId]);

  // Products Details Effect
  useEffect(() => {
    if (searchProduct.trim()) {
      const filtered = products.filter((product: any) =>
        product.name.toLowerCase().includes(searchProduct.toLowerCase())
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]); // Clear the dropdown when input is empty
    }
  }, [searchProduct, products]);

  useEffect(() => {
    const totalAmount = selectedProducts.reduce((sum, product: any) => {
      return sum + product.quantity * product.costPrice;
    }, 0);
    const discountValue =
      discountAmountType === "%"
        ? (totalAmount * parseInt(discount)) / 100
        : parseInt(discount);
    setTotal(totalAmount);
    setTotalWithGst(totalAmount + (totalAmount * parseInt(gst)) / 100);
    setDiscountAmount(discountValue);
  }, [
    selectedProducts,
    total,
    totalWithGst,
    discountAmount,
    gst,
    discount,
    discountAmountType,
  ]);

  // Valid Form
  const isFormValid = () => {
    return (
      referenceNO &&
      PAYMENT_TERMS[paymentTerms] &&
      PAYMENT_MODE[paymentMode] &&
      gst && // Ensure gst is not null
      discount && // Ensure discount is not null
      adjustment && // Ensure adjustment is not null
      contactPN &&
      address &&
      vendorId &&
      selectedProducts.length > 0
    );
  };

  // Get PreRequired data function
  const getPreRequiredDetails = async () => {
    setLoading(true);
    const PO = await getPONo();
    const BillNO = await getBillNo();
    const response = await getVendors();
    const productsResponse = await getProducts();

    setAllVendors(response.data);
    setPONo(PO.data);
    setBillNo(BillNO.data);
    setProducts(productsResponse.data);
    setLoading(false);
  };

  // Vendor Select
  const handleVendorSelect = (vendor: any) => {
    setVendorId(vendor.id);
    setSearchVendor(vendor.name);
    setEMail(vendor.email);
    setContactNo(
      vendor.contacts.find((contact: any) => contact.primary === true)
        .contactNumber
    );
  };

  // Product Select
  const handleProductSelect = (product: any) => {
    setSearchProduct(product.name);
    setSelectedProduct(product);
    setSelectedProducts([]);
  };

  // Add to Bill
  const addToBill = () => {
    if (!qty) {
      toast.error("Add Quantity!!!");
      return;
    } else {
      setSelectedProducts([
        ...selectedProducts,
        {
          productId: selectedProduct?.id,
          image: selectedProduct?.image,
          name: selectedProduct.name,
          totalQuantity: selectedProduct.totalQuantity,
          quantity: qty,
          costPrice: selectedProduct?.costPrice,
        },
      ]);
    }
    setQty("");
    setSearchProduct("");
    setSelectedProduct({});
  };

  const handleCloseDelete = () => {
    navigate(ROUTES.PURCHASE_ORDERS);
    setShowDelete(false);
  };

  const handleClose = () => {
    if (state.title === "New Purchase Order") {
      navigate(ROUTES.PURCHASE_ORDERS);
    } else {
      navigate(ROUTES.BILLS);
    }
  };

  const handleClickSaveAs = () => {
    navigate(ROUTES.ALL_PURCHASE_ORDERS);
  };

  // Send Mail
  const sendMail = async () => {
    try {
      const preparedData: NEW_PURCHASE_ORDER_TYPE = {
        poNumber: poNo,
        billNumber: billNo,
        referenceNumber: referenceNO,
        orderDate: orderDate.toISOString(),
        expectedDeliveryDate: eDeliveryDate.toISOString(),
        paymentTerms: PAYMENT_TERMS[paymentTerms],
        paymentMode: PAYMENT_MODE[paymentMode],
        gst: gst,
        discount: discount,
        discountAmountType: DISCOUNT_AMOUNT_TYPES[discountAmountType],
        adjustment: adjustment,
        deliveryPersonName: contactPN,
        deliveryAddress: address,
        vendorId: vendorId,
        products: selectedProducts.map((product: any) => ({
          productId: product.productId,
          quantity: product.quantity.toString(),
          costPrice: product.costPrice.toString(),
        })),
      };

      const response = await CreatePO(preparedData);
      if (response.data.id) {
        navigate(ROUTES.SENDMAIL, {
          state: {
            poNo: poNo,
            toEmail: eMail,
            orderDate,
            eDeliveryDate,
            paymentTerms,
            paymentMode,
            gst,
            discount,
            adjustment,
            discountAmountType,
            total,
            totalWithGst,
            discountAmount,
            poId: response.data.id,
          },
        });
      } else {
        if (state.title === "New Purchase Order") {
          navigate(ROUTES.PURCHASE_ORDERS);
        } else {
          navigate(ROUTES.BILLS);
        }
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="p-3">
      <div className="flex justify-content-between align-items-center">
        <h4 className="mb-4 fw-bold">{title}</h4>
        <CloseButton onClick={handleClose} />
      </div>
      {/* General Info */}
      <div className="p-3 bg-light rounded-4">
        <h6 className="fw-semibold mb-4 text-lg">General Information</h6>
        <div className="row">
          <div className="col-md-6 col-lg-3">
            <VerticalInput
              label="Purchase Order#"
              value={poNo}
              // handleChange={setPONo}
              placeHolder="PO - 0007"
              readOnly={true}
            />
          </div>
          <div className="col-md-6 col-lg-3">
            <VerticalInput
              label="Bill Number#"
              value={billNo}
              // handleChange={setBillNo}
              placeHolder="PO - 0007"
              readOnly={true}
            />
          </div>
          <div className="col-md-6 col-lg-3">
            <VerticalInput
              label="Reference Number"
              value={referenceNO}
              handleChange={setReferenceNO}
              placeHolder="210111121"
            />
          </div>
          <div className="col-md-6 col-lg-3 text-sm">
            <label className="form-label fw-semibold text-muted">
              Order Date
            </label>
            <DatePicker
              selected={orderDate}
              // onChange={(date: any) => setOrderDate(date)}
              selectsStart
              startDate={orderDate}
              dateFormat="dd/MM/yyyy"
              popperPlacement="bottom-start"
              customInput={<Form.Control />}
              className="border-none shadow-none text-sm"
              readOnly={true}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-6 col-lg-3 text-sm">
            <label className="form-label fw-semibold text-muted">
              Expected Delivery Date
            </label>
            <DatePicker
              selected={eDeliveryDate}
              onChange={(date: any) => setEDeliveryDate(date)}
              selectsStart
              startDate={eDeliveryDate}
              dateFormat="dd/MM/yyyy"
              popperPlacement="bottom-start"
              customInput={<Form.Control />}
              className="border-none shadow-none text-sm "
            />
          </div>
        </div>
      </div>

      {/* Vendor Info */}
      {typeOfForm !== "Customer" && (
        <div className="p-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h6 className="fw-semibold text-lg">Vendor Information</h6>
            <a href="#/" className="text-xs fw-semibold text-decoration-none">
              Add new Vendor?
            </a>
          </div>
          <div className="flex justify-content-between gap-4">
            <div className="w-100 row text-sm">
              <label className="form-label fw-semibold text-muted">Name</label>
              <SearchInput
                placeholder="Search your vendor name, contact name, n...."
                value={searchVendor}
                onChange={setSearchVendor}
              />
              <div className="mt-2 flex flex-column gap-1">
                {filteredVendors.length > 0 &&
                  filteredVendors.map((item: any) => {
                    return (
                      <div
                        key={item.id}
                        className="flex justify-content-around rounded-3 hover:bg-primary p-2 w-full"
                        onClick={() => {
                          handleVendorSelect(item);
                        }}
                      >
                        <div className="w-3">
                          <Image
                            src={item.logoUrl}
                            height={30}
                            width={30}
                          ></Image>
                        </div>
                        <div className="w-7 flex align-items-center">
                          {item.name}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="w-100">
              <VerticalInput
                label="E-Mail"
                value={eMail}
                handleChange={setEMail}
                placeHolder="abc@gmail.com"
                readOnly={true}
              />
            </div>
            <div className="w-100">
              <VerticalInput
                label="Contact Number"
                value={contactNo}
                handleChange={setContactNo}
                placeHolder="+91 1234567890"
                readOnly={true}
              />
            </div>
          </div>
        </div>
      )}

      {/* Delivery Address */}
      {typeOfForm === "NewPO" && (
        <div className="p-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h6 className="fw-semibold text-lg">Delivery Address</h6>
            <a href="#/" className="text-xs fw-semibold text-decoration-none">
              Change Address
            </a>
          </div>
          <div className="flex justify-content-between gap-4">
            <div className="w-100">
              <VerticalInput
                label="Contact Person Name"
                value={contactPN}
                handleChange={setContactPN}
                placeHolder="Enter Name"
              />
            </div>
            <div className="w-100">
              <VerticalInput
                label="Address"
                value={address}
                handleChange={setAddress}
                placeHolder="Enter Address"
              />
            </div>
          </div>
        </div>
      )}

      {/* Customer Info */}
      {typeOfForm === "Customer" && (
        <div className="p-3">
          <h6 className="fw-semibold mb-4 text-lg">Customer Information</h6>
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <VerticalInput
                label="Contact Number"
                value={contactNo}
                handleChange={setContactNo}
                placeHolder="+91 9192111023"
              />
            </div>
            <div className="col-md-6 col-lg-3">
              <VerticalInput
                label="Customer ID"
                value={customerId}
                handleChange={setCustomerId}
                placeHolder="+91 9192111023"
              />
            </div>
            <div className="col-md-6 col-lg-3">
              <VerticalInput
                label="Name"
                value={customerName}
                handleChange={setCustomerName}
                placeHolder="Customer Name"
              />
            </div>
            <div className="col-md-6 col-lg-3">
              <VerticalInput
                label="E-Mail"
                value={eMail}
                handleChange={setEMail}
                placeHolder="abc@gmail.com"
              />
            </div>
          </div>
        </div>
      )}

      {/* Type of Sale */}
      {typeOfForm === "Customer" && (
        <div className="p-3">
          <h6 className="fw-semibold mb-4 text-lg">Sales Type</h6>
          <div className="row gap-2 w-2">
            <div className="text-sm text-secondary fw-bold">Type of Sale</div>
            <Dropdown1
              title={typeOfSale}
              titles={["In Store", "Online"]}
              placeholderText=""
              onSelect={setTypeOfSale}
            />
          </div>
        </div>
      )}

      {/* Product Info */}
      <div className="p-3">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h6 className="fw-semibold text-lg">Product Information</h6>
          <a href="#/" className="text-xs fw-semibold text-decoration-none">
            Add new Product?
          </a>
        </div>
        <div className="flex gap-3">
          <div className="flex d-flex flex-column">
            <SearchInput
              placeholder="Search for existing product"
              value={searchProduct}
              onChange={setSearchProduct}
            />
            <div className="flex flex-column gap-1 mt-2">
              {filteredProducts.length > 0 &&
                filteredProducts.map((item: any) => {
                  return (
                    <div
                      key={item.id}
                      className="flex justify-content-around rounded-3 hover:bg-primary p-2"
                      onClick={() => {
                        handleProductSelect(item);
                      }}
                    >
                      <div className="w-3">
                        <Image src={item.image} height={30} width={30}></Image>
                      </div>
                      <div className="w-7 flex align-items-center">
                        {item.name}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div>
            <input
              type="number"
              value={qty}
              onChange={(e: any) => {
                setQty(e.target.value);
              }}
              className="form-control text-sm"
              placeholder={"Qty"}
            />
          </div>
          <div>
            <Button
              variant="dark"
              className="rounded-3 text-sm"
              onClick={addToBill}
            >
              Add to Bill <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </div>
        <Table className="p-table transparent-table mt-3">
          <thead className={`bg-light text-sm rounded-t-3`}>
            <tr>
              <td rowSpan={3}>Item Details</td>
              <td>Purchase Order Quantity</td>
              <td>Rate (in ₹)</td>
              <td>Amount (in ₹)</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {selectedProducts.map((product: any) => {
              return (
                <tr className="border-bottom table-tr" key={product.id}>
                  <td className="w-5">
                    <div className="d-flex align-items-center">
                      <Image
                        src={product.image}
                        height={45}
                        width={45}
                        className="border rounded-3 border-2"
                        style={{
                          marginRight: "10px",
                        }}
                      />
                      <div className="row">
                        <div className="w-100 text-sm flex align-items-center justify-content-between gap-1">
                          {product.name}
                        </div>
                        <div className="text-xs text-secondary fw-bold">
                          Stock in hand : {product.totalQuantity}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control w-50"
                      value={product.quantity}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control w-50"
                      value={product.costPrice}
                    />
                  </td>
                  <td className="font-bold">
                    {product.quantity * product.costPrice}
                  </td>
                  <td>
                    <FontAwesomeIcon icon={faTrash} color="red" />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      {/* Payment terms */}
      <div className="flex p-3 justify-content-between mb-7">
        <div className="w-50 flex flex-column gap-4">
          <h6 className="fw-semibold text-lg">Payment</h6>
          <div className="flex gap-3">
            <div className="row gap-2 w-50">
              <div className="text-sm text-secondary fw-bold">
                Payment Terms
              </div>
              <Dropdown1
                title={paymentTerms}
                titles={["Due on Receipt", "NET 15", "NET 30", "NET 45"]}
                placeholderText=""
                onSelect={setPaymentTerms}
              />
            </div>
            <div className="row gap-2 w-50">
              <div className="text-sm text-secondary fw-bold">Payment Mode</div>
              <Dropdown1
                title={paymentMode}
                titles={[
                  "CASH",
                  "UPI",
                  "Credit Card",
                  "Debit Card",
                  "Net Banking",
                ]}
                placeholderText=""
                onSelect={setPaymentMode}
              />
            </div>
          </div>
          <div className="text-sm text-secondary">
            <span className="fw-bold mr-2">Note:</span>All products ordered must
            meet the specified specifications and quality requirements as
            detailed in purchase order.
          </div>
        </div>
        {/* Sub Totals, GST, ETC.. */}
        <div className="d-flex flex-column gap-3 text-sm p-3 px-4 bg-light rounded-3 w-50">
          <div className="d-flex justify-content-between">
            <span className="detail-text fw-bold">
              Sub Total{" "}
              <span className="text-xs fw-normal">(inclusive GST)</span>
            </span>
            <span className="detail-amount">₹{totalWithGst}</span>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <span className="detail-text fw-bold">
              GST{" "}
              <span className="text-xs fw-normal">(Goods & Service Tax)</span>
            </span>
            <div className="d-flex justify-content-between align-items-center w-6">
              <div className="w-7 flex align-items-center">
                <input
                  type="number"
                  value={gst}
                  className="p-2 rounded-start w-100 border outline-none text-center"
                  onChange={(e) => {
                    setGst(e.target.value);
                  }}
                />
                <span className="border p-2 px-3 rounded-end">%</span>
              </div>
              <span className="ms-2 detail-amount">
                ₹{(total * parseInt(gst)) / 100}
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <span className="detail-text fw-bold">Discount</span>
            <div className="d-flex justify-content-between align-items-center w-6">
              <div className="w-7 flex align-items-center">
                <input
                  type="number"
                  value={discount}
                  onChange={(e) => {
                    setDiscount(e.target.value);
                  }}
                  className="p-2 rounded w-100 border outline-none text-center"
                />
                <div className="py-2">
                  <Dropdown1
                    title={discountAmountType}
                    titles={["%", "₹"]}
                    onSelect={setDiscountAmountType}
                    placeholderText=""
                  />
                </div>
              </div>
              <span className="ms-2 detail-amount">(₹ {discountAmount})</span>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <span className="detail-text fw-bold">Adjustments</span>
            <div className="d-flex justify-content-between align-items-center w-6">
              <div className="w-7 flex align-items-center">
                <input
                  type="number"
                  value={adjustment}
                  onChange={(e) => {
                    setAdjustment(e.target.value);
                  }}
                  className="p-2 rounded-start w-100 border outline-none text-center"
                />
                <span className="border p-2 px-3 rounded-end">₹</span>
              </div>
              <span className="ms-2 detail-amount">(₹{adjustment})</span>
            </div>
          </div>
          <div className="d-flex fw-bold justify-content-between text-base border-top pt-3">
            <span>Total Amount</span>
            <span>₹{totalWithGst - discountAmount - parseInt(adjustment)}</span>
          </div>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="p-2 b-bar px-3 bg-white rounded-3 shadow flex justify-content-between align-items-center text-base">
        <div className="flex gap-3 fw-bold">
          <div className="text-secondary">Total</div>
          <div>₹{totalWithGst - discountAmount - parseInt(adjustment)}</div>
        </div>
        <div className="flex gap-4">
          <Button
            variant=""
            className="rounded-3 cancel px-4 text-sm"
            onClick={() => {
              setShowDelete(true);
            }}
          >
            Cancel
          </Button>
          <Button
            variant=""
            className="rounded-3 draft text-sm"
            disabled={!isFormValid()}
          >
            Save as Draft
          </Button>
          {typeOfForm === "NewPO" ? (
            <Button
              variant=""
              className="rounded-3 hover:bg-primary text-sm bg-dark text-white"
              onClick={sendMail}
              disabled={!isFormValid()}
            >
              Add To Bill & Send
            </Button>
          ) : (
            <Dropdown>
              <Dropdown.Toggle
                variant="dark"
                id="dropdown-basic"
                className="flex gap-2 align-items-center"
              >
                Save as
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  className="flex gap-3 align-items-center text-secondary"
                  onClick={handleClickSaveAs}
                >
                  <FontAwesomeIcon icon={faClock} size="sm" /> Pending
                </Dropdown.Item>
                <Dropdown.Item
                  className="flex gap-3 align-items-center text-primary"
                  onClick={handleClickSaveAs}
                >
                  <FontAwesomeIcon icon={faCircleCheck} size="sm" />
                  Paid
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>

      <DeletePopUp
        btnName={"Save as Draft"}
        show={showDelete}
        body={
          "Any unsaved changes will be lost. Do you want to save them in drafts?"
        }
        handleClose={handleCloseDelete}
        handleSave={sendMail}
      />
    </div>
  );
};

export default NewPurchaseOrder;
