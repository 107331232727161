import { useState, useEffect, useCallback } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import { Nav, NavItem, NavLink } from "react-bootstrap";
import { Image } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

import { getSingleVendor } from "../../controller/VendorController";

import { ROUTES } from "../../navigators/Routes";

import { Animations } from "../../animations";

import RenderLottie from "../../components/RenderLottie";
import Loader from "../../components/Loader";
import {
  VendorGeneralInfo,
  VendorProducts,
  VendorTransaction,
} from "../../components/VendorFullDetailsComponent/index";

import "./style.css";

interface VendorDataTypes {
  id: number;
  name: string;
  address: string;
  email: string;
  logo: string;
  paymentTerms: string;
  currency: string;
  portalStatus: boolean;
  website: string;
  bankDetails: Array<any>;
  contacts: Array<any>;
  products: Array<any>;
  createdBy: object;
  logoUrl: string;
}

const VendorDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const vendorId = location.state.id;

  const tabs = ["General Information", "Product", "Transaction", "Mails"];

  const [screenLoading, setScreenLoading] = useState(false);
  const [isSomethingWentWrong, setIsSomethingWentWrong] = useState(false);

  const [activeTab, setActiveTab] = useState("General Information");

  const [vendorDetails, setVendorDetails] = useState<VendorDataTypes>();

  const fetchVendorDetails = useCallback(async () => {
    try {
      setScreenLoading(true);
      const response = await getSingleVendor(vendorId);
      setVendorDetails(response.data[0]);
    } catch (error) {
      setIsSomethingWentWrong(true);
      console.log(error);
    } finally {
      setScreenLoading(false);
    }
  }, [vendorId]);

  useEffect(() => {
    fetchVendorDetails();
  }, [fetchVendorDetails]);

  return screenLoading ? (
    <Loader />
  ) : isSomethingWentWrong ? (
    <RenderLottie
      animationData={Animations.SomethingWentWrong}
      title="Something Went Wrong"
      message="Please try again later or contact support for assistance."
    />
  ) : vendorDetails ? (
    <div className="p-3">
      <h4 className="mb-4 fw-bold">Brands (Vendor)</h4>
      <div
        className="mt-3 rounded-3 pl-5 p-3 flex flex-column gap-3"
        style={{ border: "1px solid #EEEEEE" }}
      >
        {/* Brand Vendor Card */}
        <div className="flex gap-4 ml-5 align-items-center">
          <Image
            src={vendorDetails.logoUrl}
            height={99}
            width={99}
            className="rounded-5 border"
          />
          <div
            className="flex flex-column justify-content-around"
            style={{ height: "79px" }}
          >
            <div className="flex gap-3">
              <span className="fw-semibold text-lg">{vendorDetails.name}</span>
              {vendorDetails.website !== "" && (
                <span className="bg-black text-sm text-white flex gap-1 align-items-center p-1 px-2 rounded-3">
                  {vendorDetails.website}
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} />{" "}
                </span>
              )}
            </div>
            <div className="text-sm flex gap-2">
              <span className="p-1 px-2 border rounded-3">
                Payables : <span className="fw-semibold">₹1000</span>
              </span>
              <span className="p-1 px-2 border rounded-3">
                Total Product :{" "}
                <span className="fw-semibold">
                  {vendorDetails.products.length}
                </span>
              </span>
            </div>
          </div>
        </div>
        {/* Back Button */}
        <button
          className="p-2 mt-3 bg-transparent border-none flex gap-2 align-items-center fw-semibold text-success"
          onClick={() => {
            navigate(ROUTES.VENDOR);
          }}
        >
          {" "}
          <FontAwesomeIcon icon={faChevronLeft} /> Back
        </button>
        <Nav className="tabs w-50 justify-content-between fw-semibold text-sm">
          {tabs.map((tab) => (
            <NavItem key={tab}>
              <NavLink
                className={`tab ${activeTab === tab ? "active" : ""}`}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        {/* Main Details */}
        {activeTab === "General Information" && (
          <VendorGeneralInfo vendorDetails={vendorDetails} />
        )}
        {activeTab === "Product" && <VendorProducts />}
        {activeTab === "Transaction" && <VendorTransaction />}
      </div>
    </div>
  ) : (
    <div>No Vendor</div>
  );
};

export default VendorDetails;
